.enrollments {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .container {
    margin-top: 3rem;
  }

  .btn-primary {
    color: $white;
    font-size: 1.4rem;
    font-weight: 600;
  }

  .select2-selection {
    background-color: $blue;
  }

  .select2-selection__rendered {
    color: $white !important;
  }

  .phone_label {
    font-size: 1.6rem;
    font-weight: 400;
  }
}
