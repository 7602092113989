@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-weight: 300;
  src: url("../fonts/IBMPlexSans-Light-300.ttf");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 300;
  src: url("../fonts/IBMPlexSans-Italic-300.ttf");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-weight: 400;
  src: url("../fonts/IBMPlexSans-Regular-400.ttf");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-style: italic;
  font-weight: 400;
  src: url("../fonts/IBMPlexSans-Italic-400.ttf");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-weight: 500;
  src: url("../fonts/IBMPlexSans-Medium-500.ttf");
}

@font-face {
  font-display: swap;
  font-family: "IBM Plex Sans";
  font-weight: 600;
  src: url("../fonts/IBMPlexSans-SemiBold-600.ttf");
}
