.input-group-text {
  align-items: center;
  background: $pastel-blue-50;
  color: $grey-300;
  display: flex;
  font-size: 1.6rem;
  justify-content: center;
  padding: 15px 22px;
}

.form-control {
  border: 1px solid $grey-200;
  color: $grey-400;
  font-size: 1.6rem;
  font-weight: 500;
  height: 56px;
  padding: 16px;

  &::placeholder {
    color: $neutral-500;
    font-size: 1.6rem;
    font-weight: 300;
    line-height: 150%;
  }
}

.form-group {
  margin-bottom: 2.5rem;

  label {
    margin-bottom: 8px;
  }
}

.field-with-errors {
  border-color: $red;
}

.error-messages {
  margin-top: 0.5rem;

  p {
    color: $red;
    font-size: 1.4rem;
    margin-bottom: 0;
  }
}

.toggle-headline,
.toggle-headline:hover {
  color: inherit;
  text-decoration: none;
}

.toggle-headline[aria-expanded="true"] > .fa-angle-down {
  transform: rotate(-180deg);
}

.form-group:has(.input-group-text) {
  input {
    border-style: none;
  }

  .input-group-text {
    border-style: none;
  }
}

.form-group input {
  color: $neutral-800;
  font-size: 1.6rem;
  font-weight: 300;
  line-height: 150%;

  &:hover {
    border-color: $blue;
  }

  &:focus {
    border: 1px solid $blue;
    box-shadow: none;
  }
}

.input-group:has(.input-group-text) {
  border: 1px solid $grey-200;
  border-radius: 4px;

  &:focus-within {
    border-color: $blue;
    box-shadow: none;
  }

  &:hover {
    border-color: $blue;
  }

  input {
    &:focus {
      border: 0;
      box-shadow: none;
      outline: none;
    }
  }
}

.form-control:focus {
  border: 0;
}

.input-group:has(.field-with-errors) {
  border-color: $red;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
}
