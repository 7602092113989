.select2 {
  font-size: 1.6rem;
}

.select2-phonenumber {
  .select2 {
    margin-right: 5px;
    width: 120px !important;
  }
}

.select2-container--default.select2-container--open .select2-selection,
.select2-container--default.select2-container--open {
  border-color: $blue;
}

.select2-selection__placeholder {
  display: flex;
  font-weight: 300;
}

.select2-dropdown {
  border: 1px solid $blue;
  box-sizing: border-box;
}

.select2-container--default {
  .select2-selection__arrow {
    align-items: center;
    color: $grey-400;
    display: flex;
    justify-content: center;
  }

  &.select2-container--open {
    .select2-selection__arrow svg {
      transform: rotate(180deg);
    }
  }

  .select2-selection--single {
    border-color: $grey-200;
    height: 56px;

    &:hover {
      border-color: $blue;
    }

    &:focus {
      outline: 0;
    }

    .select2-selection__rendered {
      color: $neutral-800;
      display: flex;
      font-size: 1.6rem;
      font-weight: 300;
      line-height: 150%;
      padding: 18px 16px;
    }

    .select2-selection__arrow {
      right: 13px;
      top: 28%;
    }

    .select2-selection__clear {
      color: $grey-400;
      position: absolute;
      right: 18px;
      top: 25%;
    }
  }

  .select2-selection--multiple {
    border-color: $grey-200;
    display: flex;
    flex-direction: column-reverse;
    font-weight: 300;
    min-height: 50px;
    padding: 2px 16px;

    &:hover {
      border-color: $blue;
    }

    .select2-selection__rendered {
      margin: 0;
    }

    .select2-selection__choice {
      background-color: $green;
      border-color: $white;
      border-radius: 100px;
      color: $white;
      font-size: 1.4rem;
      line-height: 1.6;
      margin: 2px;
      padding-left: 12px;
      padding-right: 20px;

      button {
        background-color: $green;
        border: 0;
        color: $white;
        left: auto;
        right: 6px;
      }
    }

    .select2-selection__choice:hover button {
      background-color: inherit;
    }

    .select2-search {
      width: 100%;
    }

    .select2-search--inline .select2-search__field {
      color: $neutral-800;
      font-weight: 300;
      height: 50px;
      margin: 0;
      min-width: 100%;
      outline: none;
      padding: 16px 0;
      width: 100%;

      &::placeholder {
        color: $grey-300;
        font-weight: 300;
      }
    }
  }

  &.select2-container--focus {
    .select2-selection--single,
    .select2-selection--multiple {
      border: 1px solid $blue;
      box-sizing: border-box;
    }
  }

  .select2-results__option--highlighted,
  .select2-results__option--selected {
    background-color: $grey-200;
  }

  .select2-results__option--highlighted[aria-selected="true"] {
    background-color: $pastel-blue;
    color: $grey-400;
  }

  .select2-results__option {
    color: $grey-400;
    padding: 8px 16px;
  }
}

.select2-results__option--selected {
  display: none;
}

.select2-results__options {
  &::-webkit-scrollbar-track {
    background-color: $grey-100;
  }

  &::-webkit-scrollbar {
    right: 8px;
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $grey-200;
  }
}

.field-with-errors + .select2-container {
  .select2-selection {
    border-color: $red;
  }
}
