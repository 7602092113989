.assessments {
  display: flex;
  justify-content: center;
  min-height: 100vh;

  .container {
    margin-top: 3rem;
  }

  .doac-container {
    align-items: center;
    border-bottom: 1px solid $grey-200;
    color: inherit;
    display: flex;
    text-decoration: none;
  }

  .info {
    font-size: 1.6rem;
  }

  h1 {
    color: $neutral-800;
    font-size: 2.7rem;
    font-weight: 600;
    letter-spacing: -0.01em;
    line-height: 120%;
  }

  img {
    height: 36px;
    width: 36px;
  }

  label {
    color: $dark;
    font-size: 1.6rem;
    font-weight: 400;
    letter-spacing: -0.01em;
    line-height: 150%;
  }

  p {
    color: $neutral-500;
    font-size: 1.4rem;
    font-weight: 300;
    line-height: 150%;
  }

  .usability {
    border-radius: 15px;
    min-height: 72px;
    padding: 18px;
    width: 72px;

    &--green {
      background-color: $green;
    }

    &--yellow {
      background-color: $yellow;
    }

    &--red {
      background-color: $red;
    }

    &--purple {
      background-color: $purple;
    }
  }
}
