body {
  h1 {
    font-size: 3.2rem;
    letter-spacing: -0.03em;
    line-height: 1;
  }

  h2 {
    font-size: 2.2rem;
    letter-spacing: -0.4px;
  }

  h3 {
    font-size: 1.8rem;
  }

  p {
    font-size: 1.6rem;
  }

  li,
  input,
  select {
    font-size: 1.6rem;
  }

  label {
    font-size: 1.5rem;
  }

  .fs-14 {
    font-size: 1.4rem;
    hyphens: none;
  }

  .fs-18 {
    font-size: 1.8rem;
    hyphens: none;
  }

  .small {
    color: $grey-400;
    font-size: 1.1rem;
  }

  .text-grey-400 {
    color: $grey-400;
  }

  .text-grey-300 {
    color: $grey-300;
  }

  .text-neutral-500 {
    color: $neutral-500;
  }

  .text-neutral-800 {
    color: $neutral-800;
  }

  .font-weight-medium {
    font-weight: 500;
  }
}

html,
body {
  font-size: 10px;
  -webkit-font-smoothing: antialiased;
}

body {
  display: flex;
  min-height: 100vh;
  min-height: stretch;
}

html {
  height: stretch;
}

main {
  flex: 1;
}

p {
  font-weight: 300;
}
