.btn {
  border-radius: 5px;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 2rem;
}

.btn-pastel {
  background-color: $pastel-blue;
  color: $black;
}

.link {
  color: $grey-300;
  font-size: 1.6rem;
  font-weight: 500;

  &:hover {
    color: $grey-400;
    text-decoration: none;
    transition: 0.2s;
  }
}

.dropdown {
  &.show {
    .fa-angle-down {
      transform: rotate(-180deg);
    }
  }

  .logout-link {
    color: $grey-400;
    font-size: 1.4rem;
    position: absolute;
    right: 30px;
    top: 30px;
    z-index: 1;

    &::after {
      display: none;
    }

    &:hover {
      color: $grey-400;
      text-decoration: none;
    }

    svg {
      margin-left: 10px;
    }
  }

  .dropdown-menu {
    padding: 0;
    width: 150px;

    &:hover {
      border-color: $blue;
    }

    .dropdown-item {
      color: $grey-400;
      font-size: 1.4rem;
      padding: 12px 0 12px 8px;

      &:hover {
        background: $white;
      }
    }
  }
}
