.alert {
  border: 0;
  font-size: 1.6rem;
  left: 9px;
  margin: 0 auto;
  padding: 10px;
  right: 9px;
  top: 12px;
  z-index: 20000;

  @include media-breakpoint-up(lg) {
    left: 8%;
    right: 8%;
  }

  @include media-breakpoint-up(xl) {
    left: 28%;
    right: 28%;
  }

  .close {
    font-size: 2.2rem;
    font-weight: 500;
    padding: 0;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-primary {
    background: $pastel-green;
    color: $green-dark;
  }

  &-danger {
    background: $pastel-red;
    color: $red-dark;
  }
}
