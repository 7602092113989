.doac_assessments {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  .container {
    margin-top: 3rem;
  }

  .doac-container {
    align-items: center;
    border-bottom: 1px solid $grey-200;
    color: inherit;
    display: flex;
    text-decoration: none;
  }

  .doac-content {
    margin-bottom: 67px;
    margin-top: 110px;
  }

  img {
    height: 36px;
    width: 36px;
  }

  .badge {
    color: $white;

    &-green {
      background-color: $green;
    }

    &-red {
      background-color: $red;
    }

    &-yellow {
      background-color: $yellow;
    }

    &-purple {
      background-color: $purple;
    }
  }

  .usability {
    border-radius: 18px;
    height: 72px;
    min-width: 72px;

    &--green {
      background-color: $green;
    }

    &--yellow {
      background-color: $yellow;
    }

    &--red {
      background-color: $red;
    }

    &--purple {
      background-color: $purple;
    }
  }
}
