$theme-colors: (
  "primary": $blue,
  "secondary": $grey-200,
  "success": $pastel-green,
  "info": $yellow,
  "warning": $pastel-yellow,
  "danger": $pastel-red,
  "dark": $blue,
);

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
);

$font-family-sans-serif: "IBM Plex Sans", -apple-system, BlinkMacSystemFont,
  sans-serif, "Segoe UI", Roboto, Helvetica, Arial, "Apple Color Emoji",
  "Segoe UI Emoji", "Segoe UI Symbol";

$headings-font-weight: 600;

@import "bootstrap";
