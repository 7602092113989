.home {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  footer {
    background-color: $grey-100;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    text-align: center;

    p {
      color: $grey-300;
    }
  }

  .login_headers {
    color: $neutral-800;
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 150%;
  }

  .header {
    color: $blue;
    font-weight: 500;
    line-height: 143%;
  }

  .hint {
    font-style: italic;
  }

  .info {
    color: $neutral-800;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 133%;
  }
}
