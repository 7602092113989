.disclaimer-container {
  background-color: $grey-100;
  color: $grey-300;
  line-height: 1.5;
  padding: 1.6rem;

  p {
    color: $grey-300;
    font-size: 1.4rem;
    font-weight: 300;
  }

  h4,
  h5 {
    font-size: 1.4rem;
    font-weight: 300;
  }

  h5 {
    padding-top: 2rem;
  }

  #disclaimer-text {
    margin-bottom: 1rem;

    &.collapse:not(.show) {
      display: block;
      height: 75px;
      overflow: hidden;
    }

    &.collapsing {
      height: 3rem;
    }
  }

  a.collapsed,
  a:not(.collapsed) {
    color: $neutral-800;
    font-size: 1.6rem;
    font-weight: 600;
    text-decoration: none;
  }

  a.collapsed::after {
    content: "Vis mere";
  }

  a:not(.collapsed)::after {
    content: "Vis mindre";
  }
}
